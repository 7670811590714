<template>
  <common-form
      ref="form"
      url="web/machine/team-member"
      :edit="edit"
      :items="items"
      @success="$emit('success')">
  </common-form>
</template>
<script>

export default {
  data() {
    return {
      edit: false,
      treeData: [],
    }
  },
  computed: {
    items() {
      return [
        {
          type: "tree-select",
          name: "上级",
          key: "parentId",
          required: false,
          treeData: this.treeData,
          replaceFields:{
            title:"name",
            value:"id",
            key:"id"
          }
        },
        {
          type: "input",
          name: "姓名",
          key: "name",
          required: true,
        },
        {
          type: "input",
          name: "手机号",
          key: "phone",
          required: true,
        },
      ]
    }
  },
  methods: {
    show(model = {}) {
      this.edit = model.id > 0
      this.$refs.form.show(model)

      this.$get('web/machine/team-member/tree', {teamId: model.teamId}).then((r) => {
        this.treeData = r.data
      })
    },

  }
}
</script>
